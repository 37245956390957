<template>
  <div class="login">
    <el-container>
      <el-header>
        <div class="header">
          <div class="header_title">
            <span>佛山市灏海餐饮管理有限公司</span>
            <div class="loginJump" @click="loginJump">登录页</div>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="loginMain">
          <h3 class="loginTil">忘记密码</h3>
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            class="registerForm"
            label-width="100px"
            @keyup.enter.native="dataFormSubmit()"
            status-icon
          >
            <el-form-item prop="phone" label="手机号码：">
              <el-input v-model="dataForm.phone" placeholder="请输入手机号码" />
            </el-form-item>
            <el-form-item prop="password" label="新密码：">
              <el-input
                v-model="dataForm.password"
                type="password"
                placeholder="请输入新密码"
              />
            </el-form-item>
            <el-form-item prop="password2" label="确认密码：">
              <el-input
                v-model="dataForm.password2"
                type="password"
                placeholder="请再输入一次密码"
              />
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                class="code_input"
                clearable
                v-model="dataForm.code"
                placeholder="验证码"
              />
              <el-button class="send" @click="send()" :disabled="!dataForm.phone" :loading="isLoadingSend">
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{ count }}</span>
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-main>
      <el-footer>
        <div class="checkPassBtn">
          <el-button
            class="login-btn-submit"
            type="primary"
            @click="dataFormSubmit()"
            :loading="isLoading"
          >
            更改密码
          </el-button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isLoading: false,
        isLoadingSend: false,
        isOriginHei: true, // 按钮隐藏

        show: true, // 初始启用验证码
        count: "", // 初始化验证码次数
        timer: null,

        documentHeight: document.documentElement.clientHeight,  //默认屏幕高度
        dataForm: {
          phone: null,
          password: null,
          password2: null,
          code: null,
        },
        dataRule: {
          phone: [
            { required: true, validator: this.validatePhone, trigger: "blur" },
          ],
          password: [
            { required: true, message: "新密码不能为空", trigger: "blur" },
          ],
          password2: [
            { required: true, validator: this.validatePassword, trigger: "blur" },
          ],
        },
      }
    },
    mounted() {
      // window.onresize监听页面高度的变化
      window.onresize = () => {
        return (() => {
          if (this.documentHeight>document.documentElement.clientHeight) {
            this.isOriginHei = false
          } else {
            this.isOriginHei =true
          }
        })()
      }
    },
    methods: {
      // 联系电话格式验证
      validatePhone(rule, value, callback){
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        const isPhone = reg.test(value);
        if (!value) {
          callback(new Error("手机号码不能为空"));
        } else if (!isPhone) {
          return callback(new Error("请输入正确的手机号码"));
        }
        callback();
      },
      // 验证密码
      validatePassword(rule, value, callback) {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.dataForm.password) {
          callback(new Error('两次输入密码不一致！'));
        } else {
          callback();
        }
      },
      // 提交表单
      dataFormSubmit() {
        this.$refs["dataForm"].validate(valid => {
          if (valid) {
            this.isLoading = true;
            // 判断两个密码是否一样
            if (this.dataForm.password === this.dataForm.password2) {
              this.$http.httpPost(`/mobile/changePassword`, {
                phone: this.dataForm.phone,
                code: this.dataForm.code,
                newPassword: this.dataForm.password
              }).then( data => {
                if (data && data.code === 0) {
                  this.dataForm.code = null;
                  this.$router.replace({ name: "Login" });
                  this.isLoading = false;
                } else {
                  this.dataForm.code = null;
                  this.isLoading = false;
                  this.$message.error(data.msg);
                }
              });
            }
          }
        });
      },
      // 跳转到登录页
      loginJump() {
        this.$router.replace({name: "Login"});
      },
      // 验证码点击事件
      send() {
        this.isLoadingSend = true;
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        const isPhone = reg.test(this.dataForm.phone);
        if (!this.dataForm.phone) {
          this.$message.error("联系电话不能为空");
          this.isLoadingSend = false;
          return;
        } else if (!isPhone) {
          this.isLoadingSend = false;
          return;
        } else {
          const TIME_COUNT = 60; // 倒计时时间
          if (!this.timer) {
            this.$http.httpGet(`/mobile/sendSMS`, {
              phoneNumber: this.dataForm.phone,
              type: 3
            }).then( data => {
              if (data && data.code === 0) {
                // 发短信成功
                this.count = TIME_COUNT;
                this.show = false;
                this.isLoadingSend = false;
                this.timer = setInterval(() => {
                  // 倒计时
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    // 倒计时完成，重置按钮
                    this.show = true;
                    clearInterval(this.timer); // 清除定时器
                    this.timer = null;
                  }
                }, 1000);
              } else {
                this.isLoadingSend = false;
                this.$message.error(data.msg);
              }
            });
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .login::v-deep {
    height: 100%;
    .el-container {
      height: 100%;
      .el-header {
        padding: 0;
        margin: 0;
        height: auto !important;
        background: #02A7F0;
        .header_title {
          height: 47px;
          text-align: center;
          color: white;
          line-height: 47px;
          font-size: 13px;
          .loginJump {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 10px;
          }
        }

        .header_logo {
          text-align: center;
          padding: 2vh 0;
          .logo {
            height: 100px;
            max-width: 100%;
            display: block;
            margin: 0 auto;
          }
        }
      }
      .el-main {
        padding: 15px;
        .loginMain {
          position: relative;
          margin: 0 auto;
          border: 1px #efefef solid;
          .loginTil {
            font-size: 16px;
            text-align: center;
            padding: 15px 0;
            text-align: center;
            border-bottom: 1px #efefef solid;
          }
          .registerForm {
            position: relative;
            padding: 10px;
            .el-form-item {
              margin: 0 0 15px;
              .el-form-item__label {
                font-size: 12px;
                padding: 0 8px 0 0;
                line-height: 34px;
              }
              .el-form-item__content {
                font-size: 12px;
                line-height: 34px;
                .el-input {
                  font-size: 12px;
                  .el-input__inner {
                    line-height: 34px;
                    height: 34px;
                  }
                }

                .el-form-item__error {
                  top: 95%;
                }

                .code_input {
                  width: calc(100% - 88px);
                }
                .send {
                  padding: 10px;
                  font-size: 12px;
                  margin: 0 0 0 6px;
                  width: 82px;
                }
              }
            }
          }
        }
        .registerJump {
          font-size: 12px;
          padding: 10px 0;
          text-align: center;
          display: block;
          margin: 15px auto;
        }
      }
      .el-footer {
        height: auto !important;
        padding: 15px;
        .checkPassBtn {
          position: relative;
          width: 100%;
          .el-button {
            font-size: 14px;
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
</style>
